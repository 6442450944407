import React from "react";

// components
import Main from "./layouts/Main.js";

// styles
import "./App.css";
import defaultTheme from "./theme.js";
import { ThemeProvider } from '@mui/material/styles';

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={defaultTheme}>
        <Main />
      </ThemeProvider>
    </div>
  );
}

export default App;
