import React from "react";

// mui components
import Typography from '@mui/material/Typography';

// components
import NavBar from "../components/NavBar/NavBar.js";
import BudgetForm from "../components/BudgetForm/BudgetForm.js";

// firebase
import { firebaseAuth } from "../firebase.js";
import { useAuthState, useSignInWithGoogle } from "react-firebase-hooks/auth";

// styles
import logo from "../money.svg";
import styles from "./Main.module.css";

function Main() {
  const [ user, loading, error ] = useAuthState(firebaseAuth)
  const [ signInWithGoogle ] = useSignInWithGoogle(firebaseAuth);

  if (loading) {
    return (
      <div>
        <p>Initialising User...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <p>Error: {error}</p>
      </div>
    );
  }

  const loginPrompt = (
    <div className={styles.prompt} onClick={() => signInWithGoogle()}>
      <img src={logo} className="App-logo" alt="logo" />
      <Typography
        variant="h5"
        noWrap
        component="a"
      >
        Enter
      </Typography>
    </div>
  );

  return (
    <>
      <NavBar user={user} loading={loading} error={error} signInWithGoogle={signInWithGoogle} />
      { user ? <BudgetForm user={user}/> : loginPrompt }
    </>
  );
};

export default Main;
