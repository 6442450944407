import React from "react";
import { httpsCallable } from "firebase/functions";

// mui components
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

// firebase
import { firebaseFunctions } from "../../firebase.js";

// styles
import styles from "./BudgetForm.module.css";

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const addExpense = httpsCallable(firebaseFunctions, 'addExpense');

const NOW = new Date();

const DefaultValues = Object.freeze({
  Date: `${NOW.getMonth()+1}/${NOW.getDate()}/${NOW.getFullYear()}`,
  Category: "Restaurants",
  Amount: 0,
  Owner: "Shared",
  Description: "",
});

export default function BudgetForm(props) {
  const { user } = props;
  const [date, setDate] = React.useState(DefaultValues.Date);
  const [category, setCategory] = React.useState(DefaultValues.Category);
  const [amount, setAmount] = React.useState(DefaultValues.Amount);
  const [owner, setOwner] = React.useState(DefaultValues.Owner);
  const [description, setDescription] = React.useState(DefaultValues.Description);
  const [inProgress, setInProgress] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [lastUpdate, setLastUpdate] = React.useState({});

  if (user === null) {
    return <p>Loading...</p>;
  }

  if (user.uid !== "2yAoD6LdC1cb8UFBroAfld0CYuS2" && user.uid !== "fSqkSEB8FKZbEXi2V76cP5H7ukr2") {
    return <p>Not Authorized</p>;
  }

  const makePost = (updates) => {
    setInProgress(true);
    addExpense(updates)
      .then(() => {
        setLastUpdate(updates);
        setInProgress(false);
        setSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setLastUpdate(updates);
        setInProgress(false);
        setError(true);
      });
  }

  const handleSnackClose = () => {
    setSuccess(false);
    setError(false);
  };

  const handleClick = async (e) => {
    e.preventDefault();
    await makePost({date: date, category: category, amount: Number(amount), user: owner, description: description});
  }

  const handleReset = async (e) => {
    e.preventDefault();
    setDate(DefaultValues.Date);
    setCategory(DefaultValues.Category);
    setAmount(DefaultValues.Amount);
    setOwner(DefaultValues.Owner);
    setDescription(DefaultValues.Description);
  }

  const onAmountChange = (e) => {
    e.preventDefault();
    if (!isNaN(e.target.value)) {
      const val = e.target.value.replace(/^0+/, '');
      if (val > 0) {
        setAmount(val);
      } else {
        setAmount(0);
      }
    }
  }

  const clearAmountAction = () => {
    setAmount(DefaultValues.Amount);
    const input = document.getElementById("amount-input");
    if (input) {
      input.focus();
      input.select();
    }
  };

  const clearDescriptionAction = () => {
    setDescription(DefaultValues.Description);
    const input = document.getElementById("description-input");
    if (input) {
      input.focus();
      input.select();
    }
  };

  const clearAmountButton = (
    <IconButton aria-label="clear-amount" size="small" disabled={amount === DefaultValues.Amount} onClick={clearAmountAction}>
      <ClearRoundedIcon/>
    </IconButton>
  );

  const clearDescriptionButton = (
    <IconButton aria-label="clear-description" size="small" disabled={description === DefaultValues.Description} onClick={clearDescriptionAction}>
      <ClearRoundedIcon/>
    </IconButton>
  );

  return (
    <div>
      <Typography style={{fontSize: "1rem", marginTop: "16px", marginBottom: "4px"}}>
        Welcome <b>{user.email}</b>
      </Typography>
      <Grid container justifyContent="center" alignItems="center" style={{margin: "0 auto", width: "90%", maxWidth: "500px"}}>
        <Grid item xs={12} alignSelf="right" sx={{margin: "4px"}}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleReset}
            className={styles.resetButton}
            disabled={inProgress || (date === DefaultValues.Date && category === DefaultValues.Category && amount === DefaultValues.Amount && owner === DefaultValues.Owner && description === DefaultValues.Description)}
          >
            Reset Form
          </Button>
        </Grid>
        <Grid item xs={12} sx={{margin: "8px"}}>
          <TextField
            id="date-input"
            label="Date"
            variant="outlined"
            fullWidth
            value={date}
            onChange={ event => { setDate(event.target.value) }}
          />
        </Grid>
        <Grid item xs={12} align="left" sx={{margin: "4px"}}>
          <FormControl fullWidth>
            <InputLabel id="category-input-select-label">Category</InputLabel>
            <Select
              labelId="category-input-select-label"
              id="category-input-select"
              value={category}
              label="Category"
              fullWidth
              onChange={ event => { setCategory(event.target.value) }}
            >
              <MenuItem value={"Income"}>Income</MenuItem>
              <MenuItem value={"332 3rd"}>332 3rd</MenuItem>
              <MenuItem value={"Utilities"}>Utilities</MenuItem>
              <MenuItem value={"Groceries"}>Groceries</MenuItem>
              <MenuItem value={"Restaurants"}>Restaurants</MenuItem>
              <MenuItem value={"Clothing"}>Clothing</MenuItem>
              <MenuItem value={"Personal Care"}>Personal Care</MenuItem>
              <MenuItem value={"Medical"}>Medical</MenuItem>
              <MenuItem value={"Transit"}>Transit</MenuItem>
              <MenuItem value={"Home"}>Home</MenuItem>
              <MenuItem value={"Travel - Lodging"}>Travel - Lodging</MenuItem>
              <MenuItem value={"Travel - Food"}>Travel - Food</MenuItem>
              <MenuItem value={"Travel - Transit"}>Travel - Transit</MenuItem>
              <MenuItem value={"Travel - Fun"}>Travel - Fun</MenuItem>
              <MenuItem value={"Travel - Souvenirs"}>Travel - Souvenirs</MenuItem>
              <MenuItem value={"Travel - Other"}>Travel - Other</MenuItem>
              <MenuItem value={"Fun"}>Fun</MenuItem>
              <MenuItem value={"Gifts"}>Gifts</MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
              <MenuItem value={"Education"}>Education</MenuItem>
              <MenuItem value={"Pet"}>Pet</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={{margin: "4px"}}>
          <TextField
            id="amount-input"
            label="Amount"
            variant="outlined"
            fullWidth
            value={amount}
            onChange={onAmountChange}
            InputProps={{ type: "number", inputMode: 'numeric', pattern: '-?[0-9]*', endAdornment: clearAmountButton }}
          />
        </Grid>
        <Grid item xs={12} align="left" sx={{margin: "4px"}}>
          <FormControl fullWidth>
            <InputLabel id="owner-input-select">User</InputLabel>
            <Select
              id="owner-input-select"
              value={owner}
              label="User"
              fullWidth
              onChange={ event => { setOwner(event.target.value) }}
            >
              <MenuItem value={"Shared"}>Shared</MenuItem>
              <MenuItem value={"Nelson"}>Nelson</MenuItem>
              <MenuItem value={"Jingtao"}>Jingtao</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={{margin: "4px"}}>
          <TextField
            id="description-input"
            label="Description"
            variant="outlined"
            fullWidth
            value={description}
            onChange={ event => { setDescription(event.target.value) }}
            InputProps={{ endAdornment: clearDescriptionButton }}
          />
        </Grid>
        <Grid item xs={12} sx={{margin: "4px", marginTop: "12px"}}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleClick}
            className={styles.submitButton}
            disabled={inProgress || owner.length === 0 || date.length === 0 || amount === 0 || description.length === 0}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      <Snackbar open={success} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
          <b style={{marginRight: "2px"}}>${lastUpdate?.amount}</b> for <b style={{marginRight: "2px"}}>{lastUpdate?.description}</b> successfully logged!
        </Alert>
      </Snackbar>
      <Snackbar open={error} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
          <b style={{marginRight: "2px"}}>${lastUpdate?.amount}</b> for <b style={{marginRight: "2px"}}>{lastUpdate?.description}</b> failed 😥
        </Alert>
      </Snackbar>
    </div>
  );
};
