import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={2048}
    height={2048}
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 2048 2048"
    {...props}
  >
    <defs>
      <clipPath id="id0">
        <path d="M1024 0c565.539 0 1024 458.461 1024 1024s-458.461 1024-1024 1024S0 1589.539 0 1024 458.461 0 1024 0z" />
      </clipPath>
      <style>{"\n      .fil4{fill:#33691e}.fil1{fill:#e6c047}\n    "}</style>
    </defs>
    <g id="Layer_x0020_1">
      <path
        d="M1024 0c565.539 0 1024 458.461 1024 1024s-458.461 1024-1024 1024S0 1589.539 0 1024 458.461 0 1024 0z"
        style={{
          fill: "#ffd54f",
        }}
      />
      <g
        style={{
          clipPath: "url(#id0)",
        }}
      >
        <g id="_341211520">
          <path
            id="_3329028481"
            className="fil1"
            d="M1572.63 670.189 2708.51 1806.07l1.48 1.88 1.38 1.95 1.3 2.01 1.2 2.09 1.1 2.14 1 2.2.89 2.25.78 2.31.67 2.36.56 2.4.44 2.44.31 2.48.2 2.52.06 2.55L1584 701.773l-.06-2.552-.2-2.519-.31-2.483-.44-2.443-.56-2.401-.67-2.357-.78-2.308-.9-2.254-.99-2.201-1.1-2.142-1.2-2.081-1.3-2.018-1.38-1.947z"
          />
          <path
            id="_332902632"
            className="fil1"
            d="M1584 701.773 2719.88 1837.65v481.81L1584 1183.58z"
          />
          <path
            id="_332903496"
            className="fil1"
            d="m1584 1183.58 1135.88 1135.88-.06 2.55-.2 2.52-.31 2.48-.44 2.45-.56 2.4-.67 2.35-.78 2.31-.89 2.26-1 2.2-1.1 2.14-1.2 2.08-1.3 2.02-1.38 1.95-1.48 1.87-1.56 1.81-1.65 1.72-1135.88-1135.88 1.65-1.72 1.56-1.81 1.48-1.88 1.38-1.95 1.3-2.01 1.2-2.08 1.1-2.14.99-2.21.9-2.25.78-2.31.67-2.36.56-2.4.44-2.44.31-2.48.2-2.52z"
          />
          <path
            id="_332903664"
            className="fil1"
            d="M1569.42 1218.69 2705.3 2354.57l-.92.91-.95.87-.97.86-1 .83-1.01.8-1.04.78-1.06.75-1.07.72-1.1.7-1.11.66-1.14.64-1.15.62-1.17.58-1.19.55-1.2.52-1.22.49-1135.88-1135.89 1.22-.48 1.2-.53 1.19-.55 1.17-.58 1.15-.61 1.14-.64 1.11-.67 1.1-.69 1.07-.73 1.06-.75 1.04-.77 1.01-.81 1-.82.97-.86.94-.88z"
          />
          <path
            id="_332903784"
            className="fil1"
            d="M1552.12 1229.96 2688 2365.85v115.7L1552.12 1345.66z"
          />
          <path
            id="_332903424"
            className="fil1"
            d="M1552.12 1345.66 2688 2481.55l-.07 2.58-.19 2.54-.32 2.51-.44 2.48-.57 2.42-.68 2.39-.79 2.33-.9 2.28-1.01 2.23-1.11 2.16-1.21 2.11-1.31 2.04-1.41 1.97-1.49 1.9-1.58 1.82-1.66 1.75-1135.89-1135.88 1.67-1.75 1.58-1.82 1.49-1.9 1.41-1.97 1.31-2.04 1.21-2.11 1.11-2.16 1.01-2.23.9-2.28.79-2.34.68-2.38.56-2.43.45-2.47.32-2.51.19-2.55z"
          />
          <path
            id="_332903856"
            className="fil1"
            d="m1537.37 1381.18 1135.89 1135.88-1.75 1.66-1.82 1.58-1.9 1.5-1.97 1.4-2.04 1.31-2.11 1.21-2.16 1.11-2.23 1.01-2.28.9-2.34.8-2.38.68-2.43.56-2.47.44-2.51.32-2.55.2-2.58.06-1135.88-1135.88 2.58-.06 2.55-.2 2.51-.32 2.47-.44 2.43-.57 2.38-.68 2.34-.79 2.28-.9 2.22-1.01 2.17-1.11 2.11-1.21 2.04-1.31 1.97-1.4 1.89-1.5 1.83-1.58z"
          />
          <path
            id="_332903184"
            className="fil1"
            d="M1501.86 1395.92 2637.74 2531.8h-987.6L514.257 1395.92z"
          />
          <path
            id="_332903256"
            className="fil1"
            d="M514.257 1395.92 1650.14 2531.8l-2.58-.06-2.55-.2-2.51-.32-2.47-.44-2.43-.56-2.38-.68-2.34-.8-2.28-.9-2.22-1.01-2.17-1.11-2.1-1.21-2.04-1.31-1.98-1.4-1.89-1.5L482.315 1384.42l1.898 1.5 1.972 1.4 2.039 1.31 2.105 1.21 2.166 1.11 2.225 1.01 2.282.9 2.334.79 2.382.68 2.429.57 2.472.44 2.511.32 2.546.2z"
          />
          <path
            id="_341210968"
            className="fil1"
            d="M557.739 652.079h976.567c13.688 0 26.119 5.584 35.114 14.58 8.997 8.995 14.58 21.425 14.58 35.114v481.805c0 13.688-5.583 26.117-14.58 35.114a49.911 49.911 0 0 1-17.3 11.272v115.7c0 13.844-5.649 26.416-14.745 35.513-9.099 9.098-21.669 14.744-35.512 14.744H514.256c-13.843 0-26.415-5.646-35.512-14.745-9.098-9.096-14.746-21.668-14.746-35.512v-487.25c0-13.843 5.648-26.415 14.746-35.51 7.715-7.717 17.93-12.952 29.3-14.364V701.773c0-13.69 5.584-26.12 14.58-35.114 8.995-8.996 21.425-14.58 35.115-14.58z"
          />
        </g>
      </g>
      <path
        d="M1024 0c565.539 0 1024 458.461 1024 1024s-458.461 1024-1024 1024S0 1589.539 0 1024 458.461 0 1024 0z"
        style={{
          fill: "none",
        }}
      />
      <path
        d="M557.739 652.079h976.567c13.688 0 26.119 5.584 35.114 14.58 8.997 8.995 14.58 21.425 14.58 35.114v481.805c0 13.688-5.583 26.117-14.58 35.114-8.995 8.995-21.426 14.58-35.114 14.58H557.739c-13.689 0-26.119-5.585-35.115-14.58-8.996-8.997-14.58-21.426-14.58-35.114V701.773c0-13.69 5.584-26.12 14.58-35.113 8.996-8.997 21.425-14.581 35.115-14.581z"
        style={{
          fill: "#558b2f",
        }}
      />
      <path
        className="fil4"
        d="M514.257 808.158h987.607c13.842 0 26.413 5.647 35.511 14.745 9.098 9.096 14.745 21.666 14.745 35.511v487.251c0 13.842-5.647 26.413-14.745 35.511-9.098 9.097-21.668 14.745-35.511 14.745H514.257c-13.844 0-26.414-5.648-35.512-14.745-9.097-9.098-14.745-21.668-14.745-35.511V858.414c0-13.845 5.648-26.415 14.745-35.511 9.098-9.098 21.666-14.745 35.512-14.745z"
      />
      <path
        d="M514.257 824.892c-8.927 0-17.373 3.539-23.68 9.845-6.306 6.305-9.843 14.753-9.843 23.677v487.251c0 8.925 3.537 17.372 9.844 23.679 6.306 6.306 14.753 9.843 23.68 9.843h987.606c8.925 0 17.372-3.537 23.679-9.843 6.306-6.307 9.843-14.754 9.843-23.679v-487.25c0-8.925-3.537-17.373-9.843-23.678-6.307-6.306-14.754-9.845-23.679-9.845H514.257z"
        style={{
          fill: "#689f38",
        }}
      />
      <path
        className="fil4"
        d="M503.615 837.239h111.614a102.93 102.93 0 0 1 1.804 6.452c1.839 7.529 2.816 15.658 2.816 24.294 0 28.061-11.371 53.463-29.757 71.85-18.383 18.38-43.787 29.755-71.848 29.755-7.36 0-14.14-.653-20.303-1.896l-.845-.175V843.756c0-1.76.744-3.385 1.937-4.579 1.195-1.195 2.82-1.938 4.582-1.938zm0 535.001h111.614a102.93 102.93 0 0 0 1.804-6.452c1.839-7.529 2.816-15.658 2.816-24.294 0-28.061-11.371-53.463-29.757-71.85-18.383-18.38-43.787-29.755-71.848-29.755-7.36 0-14.14.653-20.303 1.896l-.845.175v123.763c0 1.76.744 3.385 1.937 4.58 1.195 1.194 2.82 1.937 4.582 1.937zm1008.895 0h-111.614a102.93 102.93 0 0 1-1.804-6.452c-1.839-7.529-2.816-15.658-2.816-24.294 0-28.061 11.371-53.463 29.757-71.85 18.383-18.38 43.787-29.755 71.848-29.755 7.36 0 14.14.653 20.303 1.896l.845.175v123.763c0 1.76-.744 3.385-1.938 4.58-1.194 1.194-2.82 1.937-4.58 1.937zm0-535.001h-111.614c-.667 2.119-1.27 4.27-1.802 6.451-1.84 7.53-2.818 15.66-2.818 24.295 0 28.061 11.371 53.463 29.757 71.85 18.383 18.38 43.787 29.755 71.848 29.755 7.36 0 14.14-.654 20.303-1.896l.845-.175V843.756c0-1.76-.743-3.385-1.938-4.579-1.194-1.195-2.82-1.938-4.58-1.938zm-504.45 21.437c67.2 0 128.041 27.241 172.082 71.282 44.041 44.04 71.282 104.88 71.282 172.08 0 67.201-27.241 128.042-71.282 172.083-44.041 44.04-104.882 71.282-172.082 71.282-67.2 0-128.041-27.241-172.082-71.282s-71.28-104.882-71.28-172.082c0-67.2 27.239-128.041 71.28-172.081 44.041-44.041 104.882-71.282 172.082-71.282z"
      />
      <path
        d="M1006.16 1250.15v-29.41c-14.733-1.842-26.675-5.134-35.883-9.877-9.208-4.743-17.188-12.444-23.884-22.991-6.697-10.603-10.604-23.494-11.664-38.729l29.576-5.58c2.29 15.792 6.307 27.4 12.111 34.82 8.258 10.492 18.192 16.296 29.744 17.468v-93.64c-12.11-2.29-24.443-6.976-37.11-14.064-9.376-5.245-16.575-12.5-21.654-21.763-5.077-9.264-7.59-19.755-7.59-31.53 0-20.926 7.424-37.891 22.211-50.838 9.934-8.762 24.666-14.063 44.143-16.016v-14.062h17.355V968c17.075 1.618 30.693 6.641 40.682 15.012 12.89 10.658 20.591 25.335 23.214 43.974l-30.413 4.575c-1.73-11.551-5.357-20.424-10.883-26.563-5.468-6.138-13.002-10.212-22.6-12.164v84.822c14.789 3.74 24.61 6.641 29.41 8.707 9.15 4.017 16.628 8.928 22.377 14.676 5.804 5.803 10.268 12.667 13.337 20.592 3.126 7.98 4.687 16.574 4.687 25.837 0 20.425-6.529 37.39-19.475 51.006-12.947 13.673-29.745 20.927-50.336 21.93v29.746h-17.355zm0-257.652c-11.441 1.73-20.482 6.306-27.066 13.728-6.585 7.422-9.878 16.183-9.878 26.341 0 9.989 2.79 18.415 8.427 25.167 5.637 6.752 15.123 12.165 28.517 16.183v-81.419zm17.355 203.353c11.44-1.395 20.871-6.361 28.349-14.844 7.477-8.482 11.217-19.029 11.217-31.585 0-10.66-2.679-19.253-7.925-25.727-5.301-6.472-15.848-12.276-31.641-17.41v89.566z"
        style={{
          fill: "#e6ee9c",
          fillRule: "nonzero",
        }}
      />
    </g>
  </svg>
);
export default SVGComponent;
