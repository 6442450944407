import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#28b67e",
      contrastText: "white",
    },
    type: 'light',
  },
  typography: {
    allVariants: {
      fontFamily: "Lato",
      letterSpacing: "-0.025rem",
    },
  },
  components: {
    // Name of the component
    MuiInputBase: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          backgroundColor: "#d3dcde",
        },
      },
    },
  },

});

export default defaultTheme;
