import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDrx-bQDeSwuH3nSY8gVJKMkNT30JvCFG8",
  authDomain: "firewatch-1f3ff.firebaseapp.com",
  projectId: "firewatch-1f3ff",
  storageBucket: "firewatch-1f3ff.appspot.com",
  messagingSenderId: "567220255020",
  appId: "1:567220255020:web:0ee7e5f5ad2c9281371489",
  measurementId: "G-BDDRRB5V47"
};

const firebaseApp = initializeApp(firebaseConfig);

const firebaseAuth = getAuth(firebaseApp);

const firebaseAnalytics = getAnalytics(firebaseApp);

const firebaseFunctions = getFunctions(firebaseApp);

export {
  firebaseConfig,
  firebaseApp,
  firebaseAuth,
  firebaseAnalytics,
  firebaseFunctions
};
